import React from 'react';
import { Container, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import LetsikringVedKritiskSygdomImg from '../../images/letsikringvedkritisksygdom.jpg';
import LetsikringAfIndtagtVedPensionImg from '../../images/letsikringafindtaegtvedpension.jpg';
import LetsikringAfBarnVedDoed from '../../images/letsikringafbarnveddoed.jpg';
import LetsikringVedDoed from '../../images/letsikringveddoed.jpg';
import ImageLink from '../common/ImageLink';
import appRoutes from '../../routes/appRoutes';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../util/formatUtils';
import { Link } from 'react-router-dom';

const LetsikringVedSygdom = props => {
  const satser = useSelector(state => state.satser.satser);

  if (!satser)
    return null;

  return (
    <div>
      <Header as="h2" textAlign="center">Letsikring ved sygdom</Header>
      <Divider hidden />
      <Container text>

        <Divider hidden />
        <p>Med en Letsikring ved sygdom kan du sikre dig selv, hvis du bliver syg eller kommer ud for en ulykke og
          dermed mister din indtægt. </p>
        <p>En Letsikring ved sygdom sikrer dig en indtægt, hvis du mister halvdelen af din erhvervsevne i mere end tre
          måneder. Vurderingen af dit erhvervsevnetab er uafhængigt af, om du er berettiget til ydelser fra det
          offentlige. Udbetalingen sker månedsvis og kan sammen med en eventuel offentlig ydelse give dig økonomisk
          tryghed i en svær situation. </p>

        <Segment basic style={{ backgroundColor: '#f6f0e7' }}>
          <p><b>EarlyCare</b></p>
          <p>Vidste du, at du via din Letsikring ved sygdom har du adgang til EarlyCare?<br />
            Fysiske og psykiske lidelser, og i særdeleshed stress, kan få alvorlige konsekvenser, hvis de får lov til
            at vokse sig store. Derfor er der ingen grund til at vente med at gøre noget, hvis du bliver ramt. Jo
            hurtigere du taler med nogen, des hurtigere kan du få rådgivning og komme videre. </p>
          <p>Har du behov for hjælp?</p>
          <p>Ring til PFA Pension på 7080 5500. Faguddannet sundhedspersonale står klar til at give forebyggende
            rådgivning og vurdere mulighederne for eventuel behandling. En kontaktperson kan hjælpe dig, hvis der er
            mulighed for at du kan forblive i arbejde eller trygt vende tilbage, hvis du er i risiko for eller allerede
            er langtidssygemeldt. Du får også hjælp til at forstå de muligheder, der er på tværs af behandlingstilbud,
            patientrettigheder og patientforeninger. </p>
        </Segment>
        <Divider hidden />
        <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>

      </Container>
      <Divider section hidden />

      <Grid centered stackable columns={2}>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                     link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM} />
          <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension"
                     link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION} />
        </Grid.Row>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn"
                     link={appRoutes.LETSIKRING_AF_BARN_VED_DOED} />
          <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død"
                     link={appRoutes.LETSIKRING_VED_DOED} />
        </Grid.Row>
      </Grid>

    </div>
  );
};

export default LetsikringVedSygdom;
